import blackhole from './kindpng_4774918.png';
import './App.css';
import Canvas from "./Canvas";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={blackhole} className="App-logo" alt="logo"/>
            </header>
            <Canvas></Canvas>
        </div>
    );
}

export default App;
